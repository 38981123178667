import "./Navbar.css";
import { useState } from "react";

export const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenuOpen = () => {
        setMenuOpen(!menuOpen);
        console.log(menuOpen);
    }
    return (
        <div>
        <ul className="desktop">
            <li><a href="/factory" className="navbarLink">Create a Token</a></li>
            <li><a href="/me" className="navbarLink">My Tokens</a></li>
            <li className="navLeft"><img src="assets/bedrock.png" alt="logo" className="navLogo"></img><a className="active navbarLink" href="/">Bedrock</a></li>
        </ul>
        <ul className="mobile">
        <li className="navLeft navMob"><img src="assets/bedrock.png" alt="logo" className="navLogo"></img><a className="active navbarLink" href="/">Bedrock</a></li>
        <li className="navbarLink navMob" aria-label="Toggle Menu" onClick={toggleMenuOpen}>☰</li>
        </ul>
        <div className={"menu " + (menuOpen ? "menuOpen" : "menuClosed")}>
        <p className="navElement"><a href="/factory" className="navbarLink">Create a Token</a></p>
        <p className="navElement"><a href="/me" className="navbarLink">My Tokens</a></p>
        </div>
        </div>
    )
}