import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { TokenCreator } from './Pages/TokenCreator/TokenCreator';
import { Home } from './Pages/Home/Home';
import { MyTokens } from './Pages/MyTokens/MyTokens';


function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
      <Route path="/factory" element={<TokenCreator />} />
      <Route path="/" element={<Home />} />
      <Route path="/me" element={<MyTokens />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;