import { Navbar } from "../../Components/Navbar/Navbar";
import "./Home.css"

export const Home = () => {
    return (
        <>
        <div>
            <Navbar />
            <div className="half">
            <p className="homeTitle">Bedrock Finance</p>
            <p className="homeSubtitle">A strong foundation for your web3 journey</p>
            <a href="/factory" className="create">Create an ERC-20 Token</a>
            </div>
            <div className="half image">
                <img src="assets/btcworld3d.png" className="bitcoin" alt="bitcoin graphic"/>
            </div>
            <div className="app">
                <div className="app1">
                <p>Made By Bedrock</p>
                <p className="appTitle">ERC-20 Token Factory v1</p>
                <p className="appDescription">Build your own pre-audited cryptocurrency within seconds. Bedrock uses complex algorithms to ensure that your token is safe, gas efficient, and has no differences compared to non-automated contracts.</p>
                <p>Chains supported: Fantom (FTM)</p>
                <a href="/factory" className="appButton">Launch</a>
                </div>
            </div>
             <div class="roadmap">
    <div class="roadmaptext">
    <p class="roadmapsheading">Advancing</p>
    <p class="roadmapheading">Project Roadmap</p>
    <p class="roadmapdescription">See our previous feats and upcoming projects in advance to know whats going on behind the scenes.</p>
    <img alt="bitcoin graphic" class="astronaut" src="assets/btc3d.png"/>
    </div>
    <div class="timeline">
      <div class="timeline-item">
        <div class="timeline-content">
          <h2>Q4 2023</h2>
          <p>Launch BedrockMint v1 on Fantom (FTM)</p>
        </div>
      </div>
      <div class="timeline-item">
        <div class="timeline-content">
          <h2>Q1 2024</h2>
          <p>Add more wallet connection options</p>
          <p>Launch on two more chains</p>
          <p>Add explorer contract verification</p>
          <p>Create Discord, YouTube and Twitter</p>
        </div>
      </div>
      <div class="timeline-item">
        <div class="timeline-content">
          <h2>Q2 2024</h2>
          <p>Develop and launch BedrockMint v2 on three chains</p>
          <p>Complete major UI/UX updates</p>
        </div>
      </div>
      <div class="timeline-item">
        <div class="timeline-content">
          <h2>S2 2024</h2>
          <p>Launch BedrockMint v2 on two more chains</p>
          <p>Build blog and newsletter</p>
        </div>
      </div>
      <div class="timeline-item">
        <div class="timeline-content">
          <h2>2025</h2>
          <p>Launch Bedrock Finance $BDRK</p>
          <p>Build blog and newsletter</p>
          <p>Launch a second Dapp along with BedrockMint</p>
        </div>
      </div>
    </div>
  </div>
            </div>
        </>
    )
}