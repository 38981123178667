import { Navbar } from "../../Components/Navbar/Navbar";
import "./MyTokens.css";

import tokenDeployerABI from '../../ABIs/tokenDeployer.json';
import ERC20ABI from '../../ABIs/ERC20.json';
import { RPC } from "../../Constants/config";
import { contractAddress } from "../../Constants/config";

import { useState, useEffect } from "react";
import Web3 from "web3";

export const MyTokens = () => {
    const [tokenData, setTokenData] = useState([]);
    const [loading, setLoading] = useState(true); // Added loading state

    function handleChainChanged() {
        window.location.reload();
    }

    useEffect(() => {
        const init = async () => {
            try {
                const web3 = new Web3(RPC);
                const tokenDeployer = await new web3.eth.Contract(tokenDeployerABI, contractAddress);
                if (typeof window.ethereum !== 'undefined') {
                window.ethereum.on('chainChanged', handleChainChanged);
                }
                
                if (typeof window.ethereum !== 'undefined') {
                    const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                    if (accounts && accounts.length > 0) {
                        const data = await tokenDeployer.methods.getTokensDeployedByUser(accounts[0]).call();
                        var tokens = [];
                        for (let contracts = 0; contracts < data.length; contracts++) {
                            const token = await new web3.eth.Contract(ERC20ABI, data[contracts]);
                            const tokenName = await token.methods.name().call();
                            const tokenSymbol = await token.methods.symbol().call();
                            const totalSupply = await token.methods.totalSupply().call();
                            const tokenDecimals = await token.methods.decimals().call();
    
                            tokens.push({
                                contractAddress: data[contracts],
                                name: tokenName,
                                symbol: tokenSymbol,
                                supply: Number(totalSupply) * (10 ** -Number(tokenDecimals)),
                                decimals: Number(tokenDecimals)
                            });
                        }
                        setTokenData(tokens);
                    } else {
                        // Metamask is not connected
                        setLoading(false);
                    }
                } else {
                    // Wallet is not installed
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching token data:", error);
                setLoading(false);
            } finally {
                setLoading(false); // Set loading to false in case of any error
            }
        };    
        init();
    }, []);

    return (
        <div>
            <Navbar />
            <div className="myTokensHeading">
                <p className="heading">My Tokens</p>
                <p className="subheading">See all the tokens you have created!</p>
            </div>

            {loading && <p className="myTokensError">Loading...</p>}

            {!loading && tokenData.length === 0 && (
                <p className="myTokensError">No tokens available.</p>
            )}

            {tokenData.map((token, index) => (
                <div key={index} className="token">
                    <p className="tokenName">{token.name} ({token.symbol})</p>
                    <p>Contract Address: {token.contractAddress}</p>
                    <p>Supply: {token.supply}</p>
                    <p>Decimals: {token.decimals}</p>
                </div>
            ))}
        </div>
    );
};
