import { useState, useEffect } from "react";
import Web3 from "web3";

import "./TokenCreator.css";
import { Navbar } from "../../Components/Navbar/Navbar";

import tokenDeployerABI from '../../ABIs/tokenDeployer.json';
import { RPC } from "../../Constants/config";
import { contractAddress } from "../../Constants/config";
import { chainID } from "../../Constants/config";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const TokenCreator = () => {
    const [name, setName] = useState("");
    const [symbol, setSymbol] = useState("");
    const [supply, setSupply] = useState("");
    const [decimals, setDecimals] = useState("");

    const [deployFee, setDeployFee] = useState(5);

    const [tokenDeployer, setTokenDeployer] = useState("");

    function handleChainChanged() {
        window.location.reload();
    }

    const setTokenName = (e) => {
        setName(e.target.value);
    };

    const setTokenSymbol = (e) => {
        setSymbol(e.target.value);
    };

    const setTokenSupply = (e) => {
        setSupply(e.target.value);
    };

    const setTokenDecimals = (e) => {
        setDecimals(e.target.value);
    };

    const isFormFilled = () => {
        return name.trim().length > 0 && symbol.trim().length > 0 && supply.trim().length > 0;
    };

    const shouldDisplayError = (value) => {
        return value.trim().length > 0;
    };

    const determineInputClass = (value) => {
        return value.trim().length > 0 ? (isValidInput(value) ? "valid" : "invalid") : "neutral";
    };

    const isValidInput = (value) => {
        return value.trim().length > 0;
    };

    const determineSupplyInputClass = (value) => {
        return value.trim().length > 0 ? (value > 0 ? "valid" : "invalid") : "neutral";
    };

    const determineDecimalsInputClass = (value) => {
        return value.trim().length > 0 ? (value >= 0 && value <= 18 ? "valid" : "invalid") : "neutral";
    };

    useEffect(() => {
        const web3 = new Web3(RPC);
        const tokenDeployer = new web3.eth.Contract(tokenDeployerABI, contractAddress);
        setTokenDeployer(tokenDeployer);
        if (typeof window.ethereum !== 'undefined') {
            window.ethereum.on('chainChanged', handleChainChanged);
            }
    }, []);

    const checkTransaction = async (_from, _to, _value, _data) => {
    try {
        const web3 = new Web3(RPC);
        await web3.eth.estimateGas({ from: _from, to: _to, value: _value, data: _data });
        return true;
    } catch (error) {
        console.error('Error estimating gas:', error);
        return error;
    }
};

const deployToken = async () => {
    try {
        setDeployFee(5);

        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        if (Number(chainId) !== chainID) {
            toast("Switch Chain to Fantom Mainnet");
            return;
        }

        if (typeof window.ethereum === 'undefined') {
            // Wallet is not installed
            return;
        }

        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];

        var _decimals = decimals ? decimals : 18;

        const tokenCreationData = await tokenDeployer.methods.deployToken(symbol, name, String(_decimals), supply).encodeABI();
        const valid = await checkTransaction(account, contractAddress, "0x" + (deployFee * (10 ** 18)).toString(16), tokenCreationData);
        if (valid === true) {
            const receipt = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    from: account,
                    to: contractAddress,
                    value: (deployFee * (10 ** 18)).toString(16),
                    data: tokenCreationData
                }]
            });

            console.log(receipt);
            toast('Token Successfully Created! Go to "My Tokens" to view!');
        } else {
            toast(String(valid));
        }
    } catch (err) {
        console.error(err);
        if (err.code === 4001) {
            // User denied or rejected request
        }
    }
};


    return (
        <div>
            <ToastContainer />
            <Navbar />
            <div className="tokenDeployer">
                <p className="title">BedrockMint v1</p>
                <p className="inputDescription">by Bedrock Finance</p>
                <div className="inputGroup">
                    <p className="inputTitle">Token Name*</p>
                    <input
                        onChange={setTokenName}
                        className={`tokenInput ${determineInputClass(name)}`}
                        placeholder="Your Token Name"
                        value={name}
                    />
                    {shouldDisplayError(name) && !isValidInput(name) && (
                        <p className="error">Name is a required field!</p>
                    )}
                    <p className="inputDescription">Example: Bitcoin</p>
                </div>
                <div className="inputGroup">
                    <p className="inputTitle">Token Symbol*</p>
                    <input
                        onChange={setTokenSymbol}
                        className={`tokenInput ${determineInputClass(symbol)}`}
                        placeholder="Your Token Symbol"
                        value={symbol}
                    />
                    {shouldDisplayError(symbol) && !isValidInput(symbol) && (
                        <p className="error">Symbol is a required field!</p>
                    )}
                    <p className="inputDescription">Example: BTC</p>
                </div>
                <div className="inputGroup">
                    <p className="inputTitle">Token Supply*</p>
                    <input
                        onChange={setTokenSupply}
                        className={`tokenInput ${determineSupplyInputClass(supply)}`}
                        placeholder="Your Token Supply"
                        type="number"
                        value={supply}
                    />
                    {shouldDisplayError(supply) && (!isValidInput(supply) || Number(supply) <= 0) && (
                        <p className="error">Please enter a valid token supply</p>
                    )}
                    <p className="inputDescription">Example: 21000000</p>
                </div>
                <div className="inputGroup">
                    <p className="inputTitle">Decimals</p>
                    <input
                        onChange={setTokenDecimals}
                        className={`tokenInput ${determineDecimalsInputClass(decimals)}`}
                        placeholder="Your Token Decimals"
                        type="number"
                        value={decimals}
                    />
                    {shouldDisplayError(decimals) && !(decimals >= 0 && decimals <= 18) && (
                        <p className="error">Decimals must be from 0 to 18</p>
                    )}
                    <p className="inputDescription">Example: 8</p>
                </div>
                <button onClick={() => deployToken()} className={`deployButton ${isFormFilled() && decimals >= 0 && decimals <= 18 && Number(supply) >= 0 ? "" : "disabled"}`} disabled={isFormFilled() && decimals >= 0 && decimals <= 18 && Number(supply) >= 0 ? false : true}>Deploy ({deployFee} FTM)</button>
                <p className="inputDescription">(*) is a required field</p>
            </div>
        </div>
    );
};

export default TokenCreator;
